// ** Logo
import logo from "@src/assets/images/logo/jellyride_launcher_styled_logo_round.png"
import logoResized from '@src/assets/images/logo/JellyRide -02_resized.png'
import { Spinner } from "reactstrap"

const SpinnerComponent = ({ image }) => {
  return (
    <div className="fallback-spinner">
      <div>
        <div>
          <img
            src={
              image
                ? image
                : logoResized
            }
            alt="logo"
            width={65}
          />
        </div>
        <div className="spinner mt-1"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
