export const DetailDialogTitleMsg = [
  // For Trips
  {
    title: "CAUTION: Publicly Searchable on Web and App",
    message:
      "When enabled, trips & activities are publicly searchable on mobile app (if activity set to Template) and can be found by other users and visitors. Make your trip or activity public, only when you are sure it's ready to be shared!",
    position: "0"
  },
  {
    title: "Open Access via Links or QR codes",
    message:
      "When enabled, users and visitors can access trips & activities via shared QR codes and links.",
    position: "1"
  },
  {
    title: "Enable Activity Copying and Sharing",
    message:
      "When enabled, all activity participants can share and duplicate activity template. Otherwise, only activity administrators can share and duplicate it.",
    position: "2"
  },
  {
    title: "Enable recurrent scheduling",
    message: "Enable periodic scheduling options for this activity.",
    position: "3"
  },
  // For Groups
  {
    title: "Multi-day Itinerary",
    message:
      "When enabled, trips & activities with start dates in this group will be treated as daily trips or activites, i.e constituting a multi-day itinerary.",
    position: "4"
  },
  {
    title: "CAUTION: Publicly Searchable on App",
    message:
      "When enabled, groups of activities are publicly searchable on mobile app (if activity set to Template) and can be found by other users and visitors. Make your group activities public, only when you are sure it's ready to be shared!",
    position: "5"
  },
  {
    title: "Open Access via Links or QR codes",
    message:
      "When enabled, users and visitors can access groups with activities via shared QR codes and links.",
    position: "6"
  },
  {
    title: "Allow Members To Create Activities",
    message:
      "When enabled, members in the group can create and add new trips & activities into the group's activity list. Otherwise, only admins can create activities in the group.",
    position: "7"
  },
  {
    title: "Allow Members To See Activities",
    message:
      "When enabled, all members in the group can see all other trips & activities in this group. Otherwise, only participants in the respective trip can see it.",
    position: "8"
  },
  {
    title: "Enable Group Copying and Sharing",
    message:
      "When enabled, all group members can share and duplicate group template. Otherwise, only group administrators can share and duplicate it.",
    position: "9"
  },
  {
    title: "Add New Participants To Group",
    message:
      "All new participants, added to any of the activity in the group, will also be automatically added as members into the parent group.",
    position: "10"
  },
  // Create Groups
  {
    title: "CAUTION: Publicly Searchable on App",
    message:
      "When enabled, groups of activities are publicly searchable on mobile app (if activity set to Template) and can be found by other users and visitors.",
    position: "11"
  },
  {
    title: "Open Access via Links or QR codes",
    message:
      "When enabled, users and visitors can access groups with activities via shared QR codes and links.",
    position: "12"
  },
  {
    title: "Allow Members To Create Activities",
    message:
      "When enabled, members in the group can create and add new trips & activities into the group's activity list. Otherwise, only admins can create activities in the group.",
    position: "13"
  },
  {
    title: "Allow Members To See Activities",
    message:
      "When enabled, all members in the group can see all other trips & activities in this group. Otherwise, only participants in the respective trip can see it.",
    position: "14"
  },
  {
    title: "Enable Group Copying and Sharing",
    message:
      "When enabled, all group members can share and duplicate group template. Otherwise, only group administrators can share and duplicate it.",
    position: "15"
  },
  {
    title: "Add New Participants To Group",
    message:
      "All new participants, added to any of the activity in the group, will also be automatically added as members into the parent group.",
    position: "16"
  },
  {
    title: "Add New Members To Activities",
    message:
      "When enabled, each new group member is automatically added to all group's trips & activities as a participant. Otherwise, member is only added to a group.",
    position: "17"
  },
  //Group Active
  {
    title: "Active Group Members",
    message: "Show only active group members.",
    position: "18"
  },
  //Trip Active
  {
    title: "Active Trip Participants",
    message: "Show only active trip participants.",
    position: "19"
  },
  //For Recurrence
  {
    title: "Start Date",
    message: "Set start date for the recurrence schedule.",
    position: "20"
  },
  {
    title: "End Date",
    message: "Set end date for the recurrence schedule.",
    position: "21"
  },
  {
    title: "Start Time",
    message: "Set daily start time for the recurrence schedule.",
    position: "22"
  },
  {
    title: "End Time",
    message: "Set daily end time for the recurrence schedule.",
    position: "23"
  },
  {
    title: "Frequency",
    message:
      "Set the periodicity of a recurrene schedule. It can be on daily or weekly basis.",
    position: "24"
  },
  // Missing
  {
    title: "Add New Members To Trips",
    message:
      "When enabled, each new group member is automatically added to all group's activities as a participant. Otherwise, member is only added to a group.",
    position: "25"
  },
  // For Create Trip
  {
    title: "CAUTION: Publicly Searchable on App",
    message:
      "When enabled, trips & activities are publicly searchable on mobile app (if activity set to Template) and can be found by other users and visitors. Make your trip or activity public, only when you are sure it's ready to be shared!",
    position: "26"
  },
  {
    title: "Enable Activity Copying and Sharing",
    message:
      "When enabled, all activity participants can share and duplicate activity template. Otherwise, only activity administrators can share and duplicate it.",
    position: "27"
  },
  {
    title: "Open Access via Links or QR codes",
    message:
      "When enabled, users and visitors can access trips via shared QR codes and links.",
    position: "28"
  },
  {
    title: "CAUTION: Setting Activity Publicly Searchable",
    message:
      "Are you sure you want to update this property? By setting to public, others user and visitors can see this trip or activity on App!",
    position: "29"
  },
  {
    title: "CAUTION: Setting Group Publicly Searchable",
    message:
      "Are you sure you want to update this property? By setting to public, other users and visitors can see this group of activities on App!",
    position: "30"
  },
  {
    title: "Offer Number",
    message: "Custom offer number that can be changed.",
    position: "31"
  },
  {
    title: "Toggle Media Visibility",
    message:
      "When this option is enabled, this media item will appear visible to users (e.g. on mobile app) if the corresponding activity is defined as audio or video guided tour (offer details settings), and if media item is matching the respective user's language. If not matching language, English media item will appear visible as default, if exists.",
    position: "32"
  },
  {
    title: "Toggle Template Status",
    message:
      "Sets whether the respective activity or trip is an expereince template or not.\n\n"
      + "NOTE: What is a template?\nA template is a placeholder for a trip or an activity that can be added to your e-shop and is publicly searchable via mobile app and web as part of your offering. Templates cannot contain activity participants.\n\n"
      + "If you want to create an activity and invite participants directly into it for real-time itinerary and location sharing, do not set this activity to a template.",
    position: "33"
  },
  {
    title: "Toggle Template Status",
    message:
      "Sets whether the respective group of activities is a template or not.\n\n"
      + "NOTE: What is a template?\nA template is a placeholder for an activity or a group of activities that can be added to your e-shop and is publicly searchable via mobile app and web as part of your offering. Templates cannot contain group activities member.\n\n"
      + "If you want to create a group of activities and invite members directly into it for real-time itinerary and location sharing, do not set this group of activities to a template.",
    position: "34"
  },
  {
    title: "Toggle Default Media",
    message:
      "Choose to set a default media item for the respective language and media type combination. Currently, for a particular language and media type combination only one media item can be added. E.g. Particular waypoint can have multiple audio items, but each item needs to be in different language.",
    position: "35"
  },
  // For Business Details - Tour Guides
  {
    title: "Tour languages",
    message:
      "Tell us all languages in which you are providing your tours tours.",
    position: "36"
  },
  {
    title: "Tour countries",
    message: "Tell us all countries that you are providing your tours in.",
    position: "37"
  },
  {
    title: "Tour locations",
    message:
      "Tell us all localities, destinations, attractions, towns where you are providing your tours.",
    position: "38"
  },
  {
    title: "Tour types",
    message:
      "Tell us tour types that you are most specialized in. Please provide at least 3 of experience types for your better visibility, but try to keep them no more than 7.",
    position: "39"
  },
  {
    title: "Tour type proposals!",
    message:
      "We are very open to hear your ideas and proposals for new, interesting or specialized tour types that are not yet there in our list.\n\nBe the first one to propose and provide support for such new tour types and secure that all such client inquiries go directly to you!",
    position: "40"
  },
  {
    title: "Maximum group size",
    message:
      "If there is some limit in the maximum number of tour participants you support, let us know so we have direct inquiries that suit you best. If no preferences, you just leave it empty.",
    position: "41"
  },
  {
    title: "Private tour offers",
    message:
      "Let us know if private tours, usually small group of friends or familiy, is something you do.",
    position: "42"
  },
  {
    title: "Do you want us to put you on a matchmaking list?",
    message:
      "This is how we can bring you more client inquiries, more visibility and more earnings! Enable this options and all client inquries that match your profile will be forwarded to you!",
    position: "43"
  },
  {
    title: "E-shop Profile Toggle",
    message:
      "When enabled, your E-shop with business profile and travel experiences oofers becomes publicly accessible and visible on the Internet.",
    position: "44"
  },
  {
    title: "Enable Booking",
    message:
      "Activate your booking with payment for your E-shop. If not enabled, offers can be only viewed. For further arrangement a client needs to contact you as an activity provider.",
    position: "45"
  },
  {
    title: "E-shop Brand Name",
    message:
      "Your E-shop brand name by which you want to be recognized (brand name can be different from your legally registered name).",
    position: "46"
  },
  {
    title: "Brand Colors",
    message:
      "Your brand colors that will be used across experiences E-shop web page.",
    position: "47"
  },
  {
    title: "Main brand color",
    message:
      "Choose your brand main color that will be used as accent and button color across experiences E-shop web page.",
    position: "48"
  },
  {
    title: "Background Opacity",
    message:
      "Choose the opacity level of the main brand color. This color (with opacity) will be used as a background color for your experiences E-shop web page.",
    position: "49"
  },
  {
    title: "Banner Section",
    message:
      "Customize banner (hero) section for your E-shop web page.",
    position: "50"
  },
  {
    title: "Heading Title",
    message: "Heading title for your E-shop web page.",
    position: "51"
  },
  {
    title: "Sub-Heading Message",
    message: "Sub-heading message for your E-shop web page.",
    position: "52"
  },
  {
    title: "Experience Offers Section",
    message:
      "Customize section where the collection of your offers is presented.",
    position: "53"
  },
  {
    title: "Experience Offers Title",
    message: "Customize the title of your offers' collection.",
    position: "54"
  },
  {
    title: "Experience Types Message",
    message:
      "Customize the message for the list of types of experiences you provide.",
    position: "55"
  },
  {
    title: "Messaging Options",
    message: "Define how visitors and clients can message you.",
    position: "56"
  },
  {
    title: "Contact via WhatsApp",
    message: "Enable if you want to show you are reachable via WhatsApp.",
    position: "57"
  },
  {
    title: "Contact via Viber",
    message: "Enable if you want to show you are reachable via Viber.",
    position: "58"
  },
  {
    title: "Contact via Skype",
    message: "Enable if you want to show you are reachable via Skype.",
    position: "59"
  },
  {
    title: "Skype Name",
    message:
      "If you want to be contacted via Skype, please provide your Skype name.",
    position: "60"
  },
  {
    title: "Contact & About Us Section",
    message:
      "Provide your contact details to make them publicly visible on your E-shop web page.",
    position: "61"
  },
  {
    title: "Privacy Policy Link",
    message:
      "Provide link to your Privacy Policy. If left empty, a parent Privacy Policy defined by JellyRide platform will be used.",
    position: "62"
  },
  {
    title: "Terms of Service Link",
    message:
      "Provide link to your Terms of Service. If left empty, a parent Terms of Service defined by JellyRide platform will be used.",
    position: "63"
  },
  {
    title: "Contact E-mail",
    message:
      "Provide your E-shop contact e-mail that page visitors and clients can contact you to.",
    position: "64"
  },
  {
    title: "Contact Phone Number",
    message:
      "Provide your E-shop contact phone number that page visitors and clients can contact you to.",
    position: "65"
  },
  {
    title: "Add Offer to E-shop Toogle",
    message: "Do you want to add offer to e-shop?",
    position: "66"
  },
  {
    title: "Offer Reference ID",
    message: "Unique offer reference ID that does not change.",
    position: "67"
  },
  {
    title: "Offer Type",
    message: "Specific type of offer like type of tours or events.",
    position: "68"
  },
  {
    title: "Experience Type",
    message:
      "For a tour type you can specify what kind of experience type this offer is.",
    position: "69"
  },
  {
    title: "Enable Offer With Price",
    message: "Select to enable setting a price for this respective offer.",
    position: "70"
  },
  {
    title: "Price Per Group",
    message: "Select if respective price is defined per group.",
    position: "71"
  },
  {
    title: "Price",
    message: "Set the total price for the respective offer. If automatic tax collection included, this price will include respective tax (VAT, GST, sales tax) as well.",
    position: "72"
  },
  {
    title: "Currency",
    message: "Set currency for the respective offer price.",
    position: "73"
  },
  {
    title: "Offer Location & Schedule",
    message: "Set details for offer's location and activity schedule.",
    position: "74"
  },
  {
    title: "Location Label",
    message:
      "Write the activity location label exactly as it will appear trip cards across the platform (e.g. as E-shop experience offer card or offer item on mobile app Explore screen).",
    position: "75"
  },
  {
    title: "Country Flag",
    message:
      "Select the country to define country's flag as it would appear on E-shop experience offer card.",
    position: "76"
  },
  {
    title: "Activity Duration",
    message:
      "Select the activity duration as it would appear on E-shop experience offer card.",
    position: "77"
  },
  {
    title: "Custom Activity Duration",
    message:
      "Write the activity duration label exactly as it would appear on E-shop experience offer card.",
    position: "78"
  },
  {
    title: "Schedule and Availability",
    message: "Select schedule and availability type for this activity offer.",
    position: "79"
  },
  {
    title: "Availability Calendar",
    message:
      "Provide one or multiple days for which this activity offer is available.",
    position: "80"
  },
  {
    title: "Offer Includes & Excludes",
    message:
      "Define explicitly items and services that are included or not included in this offer (and its price).",
    position: "81"
  },
  {
    title: "Offer Includes",
    message: "Items and services included in this offer (and its price).",
    position: "82"
  },
  {
    title: "Offer Excludes",
    message: "Items and services not included in this offer (and its price).",
    position: "83"
  },
  {
    title: "Offer Contacts & Info (optional)",
    message:
      "Enable setting specific contacts and info details for this activity offer that are generaly differing from your organization contact & info details. \n\nThis option can be used when e.g. you are selling or organizing activity on behalf of someone else actually prvoding the activity or service on the field.",
    position: "84"
  },
  {
    title: "Offer Web",
    message: "Set specific web Url for this offer.",
    position: "85"
  },
  {
    title: "Offer Social",
    message: "Set specific social media link of your choice for this offer.",
    position: "86"
  },
  {
    title: "Offer E-mail",
    message: "Set specific e-mail for this offer.",
    position: "87"
  },
  {
    title: "Offer Contact Phone Number",
    message: "Set specific contact phone number for this offer.",
    position: "88"
  },
  {
    title: "Add Offer to E-shop",
    message:
      "This offer is added to your e-shop and becomes visible and searchable on JellyRide Travel experiences marketplace.",
    position: "89"
  },
  {
    title: "Enable Instant Booking",
    message:
      "Clients can book and pay directly. No need to get confirmation for availability from your (seller's) side.",
    position: "90"
  },
  {
    title: "E-shop customization",
    message:
      "You can fully customize how your travel experience E-shop web page will appear publicly.",
    position: "91"
  },
  {
    title: "Experience Types Section",
    message:
      "Customize a section where the collection of types of expereinces you provide is presented.",
    position: "92"
  },
  {
    title: "Experience Types Title",
    message:
      "Customize the title for the list of types of experiences you provide.",
    position: "93"
  },
  {
    title: "Public Profile Tag",
    message:
      "Your E-shop name tag, part of E-shop web Url, defined as part of the Organization info setup. This tag is set on Organization tab and is mandatory for e-shop publishing!",
    position: "94"
  },
  {
    title: "Your Tour Guide Name",
    message: "Personal name or business brand that you use to present yourself or your business (e-shop) to visitors and clients.",
    position: "95"
  },
  {
    title: "Contact & About Us Section",
    message: "Contact & About Us Section.",
    position: "96"
  },
  {
    title: "Email",
    message: "The official email your business or you as an owner can be contacted to. (mandatory)",
    position: "97"
  },
  {
    title: "Phone Number",
    message: "The official phone number your business or you as an owner can be contacted to. (mandatory)",
    position: "98"
  },
  {
    title: "Organization Profile Name",
    message: "Reserve your profile name while it's available! \nChoose your profile name (tag) that will be used as an identifier for your public profile. This tag is required to be unique in the format with no spaces or special characters. Some examples: \"best-tour-guide\" or \"besttouragency\". This tag is mandatory for e-shop publish.",
    position: "99"
  },
  {
    title: "Company ID",
    message: "A unique identification number for your company. Depending on local legislation, most commonly it is related to a respective tax id number: VAT ID, Tax ID, EIN, OIB, ...",
    position: "100"
  },
  {
    title: "Address",
    message: "Company or business registration address (mandatory). Enter and pick the respective full address. (mandatory)",
    position: "101"
  },
  {
    title: "StreetAndNumber",
    message: "Street name and number automatically populated upon address selection. Can be manually edited. (mandatory)",
    position: "102"
  },
  {
    title: "Postal Code",
    message: "Postal Code automatically populated upon address selection. Can be manually edited. (mandatory)",
    position: "103"
  },
  {
    title: "Country",
    message: "Country name automatically populated upon address selection. Can be manually edited. (mandatory)",
    position: "104"
  },
  {
    title: "Organization Web Site",
    message: "Share your business or personal web site so that clients and visitors can learn more about you.",
    position: "105"
  },
  {
    title: "Facebook Page",
    message: "Share your Facebook page so that clients and visitors can learn more about you.",
    position: "106"
  },
  {
    title: "Instagram Profile",
    message: "Share your Instagram profile so that clients and visitors can learn more about you.",
    position: "107"
  },
  {
    title: "Twitter Profile",
    message: "Share your Twitter profile so that clients and visitors can learn more about you.",
    position: "108"
  },
  {
    title: "TikTok Profile",
    message: "Share your TikTok profile so that clients and visitors can learn more about you.",
    position: "109"
  },
  {
    title: "LinkedIn Page",
    message: "Share your LinkedIn page so that clients and visitors can learn more about you.",
    position: "110"
  },
  {
    title: "About",
    message: "Tell a short story about your company or yourself. Share what makes your product or service stand out, share your mission, values or references.",
    position: "111"
  },
  {
    title: "Enable Booking Inquiries",
    message: "This option enables clients to contact you via web form for booking iquiries. Otherwise, your experience offers are presented only in \"View\" mode.",
    position: "112"
  },
  {
    title: "Enable Online Payments",
    message: "This option enables clients to pay your offers online when booking enabled. You need to provide payment details to be able to receive payments from your clients.",
    position: "113"
  },
  {
    title: "Booking Inquiries Toggle",
    message: "Toggle booking inquiries?",
    position: "114"
  },
  {
    title: "Online Payments Toggle",
    message: "Toggle online payments?",
    position: "115"
  },
  {
    title: "Enable Instant Booking",
    message: "This option enables clients to pay this particular offer online when booking enabled. You need to provide payment details to be able to receive payments from your clients.",
    position: "116"
  },
  {
    title: "Instant Booking Toggle",
    message: "Toggle instant booking?",
    position: "117"
  },
  {
    title: "Organization or Owner Name",
    message: "Legal name for your registered business or your personal name as an owner or freelancer.",
    position: "118"
  },
  {
    title: "City",
    message: "City name automatically populated upon address selection. Can be manually edited. (mandatory)",
    position: "119"
  },
  {
    title: "Enable E-shop Profile",
    message: "Setting this option to true, you publish your E-shop profile publicly. If E-shop profile is not propertly set, publish will be disabled.",
    position: "120"
  },
  {
    title: "Profile Name",
    message: "Reserve your profile name while it's available! Choose your profile name (tag) that will be used as an identifier for your public profile. This tag is required to be unique in the format with no spaces or special characters. Some examples: \"best-tour-guide\" or \"besttouragency\". This tag is mandatory for e-shop publish.",
    position: "121"
  },
  {
    title: "Download",
    message: "Do you want to download it?",
    position: "122"
  },
  {
    title: "Payment request",
    message: "Do you want to create a payment request with checkout link for this booking entry?",
    position: "123"
  },
  {
    title: "Add Client Info",
    message: "You can add client info to have a better tracking of your payments and clients.",
    position: "124"
  },
  {
    title: "No Client Info",
    message: "You are about to create a payment link request without a client info. Do you want to proceed?",
    position: "125"
  }
]
