import { toast } from "react-toastify"

const isValidTypeAudio = (type) => {
    let isValid = false
    switch (type) {
        case "audio/wav":
            isValid = true
            break
        case 'audio/mp3':
            isValid = true
            break
        case 'audio/mpeg':
            isValid = true
            break
        case 'audio/aac':
            isValid = true
            break 
        case 'audio/wma':
            isValid = true
            break
        case 'audio/ogg':
            isValid = true
            break
        default:
            isValid = false
            break
    } 
    return isValid
}
const isValidTypeVideo = (type) => {
    let isValid = false
    switch (type) {
        case "video/mp4":
            isValid = true
            break
        case 'video/mov':
            isValid = true
            break
        case 'video/quicktime':
            isValid = true
            break
        case 'video/avi':
            isValid = true
            break
        case 'video/x-msvideo':
            isValid = true
            break
        case 'video/x-msvideo':
            isValid = true
            break                 
        case 'video/wmv':
            isValid = true
            break         
        default:
            isValid = false
            break
    } 
    return isValid
}
const isValidTypeDocument = (type) => {
    let isValid = false
    switch (type) {
        case "application/pdf":
            isValid = true
            break
        case 'application/doc':
            isValid = true
            break
        case 'application/docs':
            isValid = true
            break
        case 'application/msword':
            isValid = true
            break 
        case 'application/xls':
            isValid = true
            break
        case 'application/xlsx':
            isValid = true
            break
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            isValid = true
            break
        case 'application/vnd.ms-excel':
            isValid = true
            break
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            isValid = true
            break
        default:
            isValid = false
            break
    } 
    return isValid
}
export const CompressVideoAudioDoc = (e, setTempData, TempData, fileType) => {
    const file = e[0].file
    if (file.size / 1024 / 1024 > 25) {
        return toast.error("Max file size should be 25MB")
    }
    let isValid = false
    if (fileType === '2') {
        isValid = isValidTypeVideo(e[0].type)
    } else if (fileType === '4') {
        isValid = isValidTypeAudio(e[0].type)
    } else {
        isValid = isValidTypeDocument(e[0].type)
    }
    if (isValid) {
        setTempData({ ...TempData, mediaFileUrl: e[0].base64, MediaFile: e[0].file, mediaNameTag:e[0].name.replace(/\.[^/.]+$/, "") })
    } else {
        if (fileType === '2') {
            toast.error("only mp4, avi, wvm, mov formats are allowed")
        } else if (fileType === '4') {
            toast.error("only mp3, wav, acc, ogg formats are allowed")
        } else {
            toast.error("only doc, docs, pdf, xls, xlsx formats are allowed")
        }
    }
}