// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modals_WideModal__EOBCt {
  display: flex;
  max-width: 1000px;
  width: 60%;
}

.modals_SetAtStart__M-TSZ {
  display: flex;
  justify-content: start;
}

.modals_SetTextEnd__ONoDg {
  text-align: end;
}

.modals_ModalGalleryBtnContainer__4BVai {
  display: flex;
  justify-content: end;
}

@media (max-width: 467px) {
  .modals_WideModal__EOBCt {
    width: 100%;
    margin: 10px auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Modals/modals.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,iBAAA;EACA,UAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAEA;EACE,aAAA;EACA,oBAAA;AACF;;AAEA;EACE;IACE,WAAA;IACA,iBAAA;EACF;AACF","sourcesContent":[".WideModal {\n  display: flex;\n  // height: 100vh;\n  max-width: 1000px;\n  width: 60%;\n}\n\n.SetAtStart {\n  display: flex;\n  justify-content: start;\n}\n\n.SetTextEnd {\n  text-align: end;\n}\n.ModalGalleryBtnContainer {\n  display: flex;\n  justify-content: end;\n}\n\n@media (max-width: 467px) {\n  .WideModal{\n    width: 100%;\n    margin: 10px auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WideModal": `modals_WideModal__EOBCt`,
	"SetAtStart": `modals_SetAtStart__M-TSZ`,
	"SetTextEnd": `modals_SetTextEnd__ONoDg`,
	"ModalGalleryBtnContainer": `modals_ModalGalleryBtnContainer__4BVai`
};
export default ___CSS_LOADER_EXPORT___;
